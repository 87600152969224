import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import buildCdRow from "../../helpers/buildCdRow";
import getProductDataByName from "../../helpers/getProductDataByName";

const BusinessRates = () => {
  const pageData = useStaticQuery(graphql`
    query {
      allStrapiCdS(sort: { fields: strapiId, order: ASC }) {
        nodes {
          APY
          CostTier
          InterestRate
          ProductType
          RenewalTerm
          Term
          WithEligibleAccount
          strapiId
        }
      }
      allStrapiProducts {
        nodes {
          Name
          ProductData {
            name
            value
          }
        }
      }
      miscValueDates: allStrapiMiscValues(filter: { Name: { eq: "Bank Rates - Date" } }) {
        nodes {
          Value
        }
      }
    }
  `);

  const ratesDate = pageData.miscValueDates.nodes[0].Value;

  const cds = pageData.allStrapiCdS.nodes.filter((cd) => {
    return cd.ProductType === "FixedRateBusiness";
  });
  function getCdSpecialDetails() {
    const filteredCds = cds.filter((cd) => {
      return cd.Term === "13 Month CD Special";
    });

    const withEligibleAccountRow =
      filteredCds.filter((cd) => {
        return cd.WithEligibleAccount === true;
      })[0] || null;
    const withoutEligibleAccountRow =
      filteredCds.filter((cd) => {
        return cd.WithEligibleAccount === false;
      })[0] || null;

    if (!withEligibleAccountRow || !withoutEligibleAccountRow) {
      return false;
    }

    return {
      Name: "13 Month CD Special",
      EligibleCheckingInterestRate: withEligibleAccountRow.InterestRate.toFixed(2),
      EligibleCheckingAPY: withEligibleAccountRow.APY.toFixed(2),
      NonEligibleCheckingInterestRate: withoutEligibleAccountRow.InterestRate.toFixed(2),
      NonEligibleCheckingAPY: withoutEligibleAccountRow.APY.toFixed(2)
    };
  }
  const cdSpecialDetails = getCdSpecialDetails();

  /* To render a CD record, we need to know the low tier and high tier.
   * We need to hard-code the tier values here to map which CD record is for which tier.
   * This does introduce an element of brittleness by assuming the data won't be changed.
   * If the data does change, this code will need to be refactored.
   */
  const buildCdRowOpts = {
    lowTier: "$1,000 - $89,999",
    highTier: "$90,000 - $250,000"
  };

  function getProductValue(productName, productDataName) {
    return getProductDataByName(pageData.allStrapiProducts, productName, productDataName)?.value ?? "N/A";
  }

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      active: true,
      title: "Business Rates"
    }
  ];

  const title = "Business Rates";

  const SEOData = {
    title,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Learn more about the rates that apply to business savings, checking, loans and money markets from WaFd Bank. Visit WaFd Bank branch near you."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-cd-money-market-rates"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-default-wafd-logo-01282023-250.svg"
      }
    ]
  };

  function _filterCdRecords(term, renewalTerm) {
    return cds.filter((cd) => {
      return cd.Term === term && cd.RenewalTerm === renewalTerm;
    });
  }

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />

      <section id="business-rates-intro-section" className="container">
        <h1>Business Rates</h1>
        <h2>We offer competitive rates for your business needs</h2>
        <p>
          Effective <span id="rates-date">{ratesDate}</span>. Rates and <strong>annual percentage yields (APY)</strong>{" "}
          are subject to change without notice. Terms on amounts over $1,000,000 must be approved in advance. Member
          FDIC. Account service charges or withdrawals may reduce earnings. Please ask us for details.
        </p>
        <div className="row">
          {/* Business 4-5-month CD Special */}
          <div className="col-sm-6 col-lg-4 mb-4">
            <h4>
              <Link
                id="business-4-5-month-cd-specials-link"
                className="d-none d-md-inline-block"
                to="/business-banking/business-savings-account#business-savings-overview-section-cds"
              >
                Business 4-5-Month CD Special
              </Link>
              <Link
                id="business-4-5-month-cd-specials-link"
                className="d-md-none d-inline-block"
                to="/business-banking/business-savings-account#m-4-5month-cd"
              >
                Business 4-5-Month CD Special
              </Link>
              *
            </h4>
            <p>
              $1,000 - $1,000,000
              <br />
              With Eligible<sup>&dagger;</sup> Checking or $25k New Money:
              <br />
              Interest Rate/APY
              <br />
              <strong>
                <span id="4-5-month-cd-with-eligible-checking-interest-rate">
                  {getProductValue("4-5-Month CD Special", "Interest Rate - Eligible Checking")}
                </span>
                /
                <span id="4-5-month-cd-with-eligible-checking-apy">
                  {getProductValue("4-5-Month CD Special", "APY - Eligible Checking")}
                </span>
              </strong>
              <br />
              Without Checking:
              <br />
              <strong>Not Available</strong>
            </p>
            <a
              href="/locations"
              className="btn btn-primary mt-5"
              id="business-4-5-month-cd-open-account-cta"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open in Branch
            </a>
          </div>
          {/* Business 7-month CD Special */}
          <div className="col-sm-6 col-lg-4 mb-4">
            <h4>
              <Link
                id="business-7-month-cd-specials-link"
                className="d-none d-md-inline-block"
                to="/business-banking/business-savings-account#business-savings-overview-section-cds"
              >
                Business 7-Month CD Special
              </Link>
              <Link
                id="business-7-month-cd-specials-link"
                className="d-md-none d-inline-block"
                to="/business-banking/business-savings-account#m-7month-cd"
              >
                Business 7-Month CD Special
              </Link>
              *
            </h4>
            <p>
              $1,000 - $1,000,000
              <br />
              With Eligible<sup>&dagger;</sup> Checking or $25k New Money:
              <br />
              Interest Rate/APY
              <br />
              <strong>
                <span id="7-month-cd-with-eligible-checking-interest-rate">
                  {getProductValue("7-Month CD Special", "Interest Rate - Eligible Checking")}
                </span>
                /
                <span id="7-month-cd-with-eligible-checking-apy">
                  {getProductValue("7-Month CD Special", "APY - Eligible Checking")}
                </span>
              </strong>
              <br />
              Without Checking:
              <br />
              Interest Rate/APY
              <br />
              <strong>
                <span id="7-month-cd-without-eligible-checking-interest-rate">
                  {getProductValue("7-Month CD Special", "Interest Rate - Not Eligible Checking")}
                </span>
                /
                <span id="7-month-cd-without-eligible-checking-apy">
                  {getProductValue("7-Month CD Special", "APY - Not Eligible Checking")}
                </span>
              </strong>
              <br />
              <span id="7-month-cd-minimum-to-open">$1,000 minimum to open</span>
            </p>
            <a
              href="https://forms.fivision.com/wafdbank/oba/default.aspx?Applicant=NM&SelectedProduct=BC7"
              className="btn btn-primary"
              id="business-7-month-cd-open-account-cta"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open an Account
            </a>
          </div>
          {/* Business 13-Month CD Special */}
          <div className="col-sm-6 col-lg-4 mb-4">
            <h4>
              <Link
                id="business-13-month-cd-specials-link"
                className="d-none d-md-inline-block"
                to="/business-banking/business-savings-account#business-savings-overview-section-cds"
              >
                Business 13-Month CD Special
              </Link>
              <Link
                id="business-13-month-cd-specials-link"
                className="d-md-none d-inline-block"
                to="/business-banking/business-savings-account#m-7month-cd"
              >
                Business 13-Month CD Special
              </Link>
              *
            </h4>
            <p>
              $1,000 - $1,000,000
              <br />
              With Eligible<sup>&dagger;</sup> Checking or $25k New Money:
              <br />
              Interest Rate/APY
              <br />
              <strong>
                <span id="13-month-cd-with-eligible-checking-interest-rate">
                  {cdSpecialDetails.EligibleCheckingInterestRate}%
                </span>
                /<span id="13-month-cd-with-eligible-checking-apy">{cdSpecialDetails.EligibleCheckingAPY}%</span>
              </strong>
              <br />
              Without Checking:
              <br />
              Interest Rate/APY
              <br />
              <strong>
                <span id="13-month-cd-without-eligible-checking-interest-rate">
                  {cdSpecialDetails.NonEligibleCheckingInterestRate}%
                </span>
                /<span id="13-month-cd-without-eligible-checking-apy">{cdSpecialDetails.NonEligibleCheckingAPY}%</span>
              </strong>
              <br />
              <span id="13-month-cd-minimum-to-open">$1,000 minimum to open</span>
            </p>
            <a
              href="https://forms.fivision.com/wafdbank/oba/default.aspx?Applicant=NM&SelectedProduct=BC13"
              className="btn btn-primary"
              target="_blank"
              rel="noopener noreferrer"
              id="business-13-month-cd-open-account-cta"
            >
              Open an Account
            </a>
          </div>
          {/* Business 19-Month CD Special */}
          <div className="col-sm-6 col-lg-4 mb-4">
            <h4>
              <Link
                id="business-19-month-cd-specials-link"
                className="d-none d-md-inline-block"
                to="/business-banking/business-savings-account#business-savings-overview-section-cds"
              >
                Business 19-Month CD Special
              </Link>
              <Link
                id="business-19-month-cd-specials-link"
                className="d-md-none d-inline-block"
                to="/business-banking/business-savings-account#m-7month-cd"
              >
                Business 19-Month CD Special
              </Link>
              *
            </h4>
            <p>
              $1,000 - $1,000,000
              <br />
              With Eligible<sup>&dagger;</sup> Checking or $25k New Money:
              <br />
              Interest Rate/APY
              <br />
              <strong>
                <span id="19-month-cd-with-eligible-checking-interest-rate">
                  {getProductValue("19-Month CD Special", "Interest Rate - Eligible Checking")}
                </span>
                /
                <span id="19-month-cd-with-eligible-checking-apy">
                  {getProductValue("19-Month CD Special", "APY - Eligible Checking")}
                </span>
              </strong>
              <br />
              Without Checking:
              <br />
              Interest Rate/APY
              <br />
              <strong>
                <span id="19-month-cd-without-eligible-checking-interest-rate">
                  {getProductValue("19-Month CD Special", "Interest Rate - Not Eligible Checking")}
                </span>
                /
                <span id="19-month-cd-without-eligible-checking-apy">
                  {getProductValue("19-Month CD Special", "APY - Not Eligible Checking")}
                </span>
              </strong>
              <br />
              <span id="19-month-cd-minimum-to-open">$1,000 minimum to open</span>
            </p>
            <a
              href="https://forms.fivision.com/wafdbank/oba/default.aspx?Applicant=NM&SelectedProduct=BC19"
              className="btn btn-primary"
              id="business-19-month-cd-open-account-cta"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open an Account
            </a>
          </div>
          {/* Business Premium Money Market */}
          <div className="col-sm-6 col-lg-4 mb-4">
            <h4>
              <Link
                id="business-premium-money-market-link"
                className="d-none d-md-inline-block"
                to="/business-banking/business-savings-account#business-savings-overview-section"
              >
                Business Premium Money Market
              </Link>
              <Link
                id="business-premium-money-market-link"
                className="d-md-none d-inline-block"
                to="/business-banking/business-savings-account#m-business-premium"
              >
                Business Premium Money Market
              </Link>
              **
            </h4>
            {getProductValue("Premium Business Money Market", "Tier 1 Price Range")}
            <br />
            <strong>
              <span id="business-premium-money-market-tier-1-interest-rate">
                {getProductValue("Premium Business Money Market", "Tier 1 Interest Rate")} Interest Rate
              </span>
              <br />
              <span id="business-premium-money-market-tier-1-apy">
                {getProductValue("Premium Business Money Market", "Tier 1 APY")} APY
              </span>
            </strong>
            <br />
            {getProductValue("Premium Business Money Market", "Tier 2 Price Range")}
            <br />
            <strong>
              <span id="business-premium-money-market-tier-2-interest-rate">
                {getProductValue("Premium Business Money Market", "Tier 2 Interest Rate")} Interest Rate
              </span>
              <br />
              <span id="business-premium-money-market-tier-2-apy">
                {getProductValue("Premium Business Money Market", "Tier 2 APY")} APY
              </span>
            </strong>

            <p>{getProductValue("Premium Business Money Market", "Description")}</p>
            <a
              href="https://forms.fivision.com/wafdbank/oba/default.aspx?Applicant=NM&SelectedProduct=MMPB"
              className="btn btn-primary"
              target="_blank"
              rel="noopener noreferrer"
              id="business-premium-mm-open-account-cta"
            >
              Open an Account
            </a>
          </div>
        </div>
        <p id="eligible-business-checking-footnote">
          <sup>&dagger;</sup> Eligible business checking accounts include Simple Business and Business Interest
          Checking.
        </p>
        <p id="cd-special-min-balance-footnote">* $1,000 minimum to open</p>
        <p id="premium-money-market-min-balance-footnote">
          ** $100 minimum to open. $1,000 minimum to earn interest and avoid $12 monthly service charge. Maximum
          $100,000 deposit per client.
        </p>

        <div className="table-responsive">
          <table id="business-savings-rates-table" className="table mb-4">
            <thead>
              <tr>
                <th colSpan="2" className="h4">
                  <Link
                    id="business-savings-link"
                    className="d-none d-md-inline-block"
                    to="/business-banking/business-savings-account#business-savings-overview-section"
                  >
                    Business Savings
                  </Link>
                  <Link
                    id="business-savings-link"
                    className="d-md-none d-inline-block"
                    to="/business-banking/business-savings-account#m-business-savings"
                  >
                    Business Savings
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "50%" }} id="business-savings-description">
                  {getProductValue("Business Savings", "Description")}
                </td>
                <td style={{ width: "50%" }}>
                  <div id="business-savings-min-to-earn-interest">
                    {getProductValue("Business Savings", "Minimum to Earn Interest")}
                  </div>
                  <div id="business-savings-interest-rate">
                    {getProductValue("Business Savings", "Interest Rate")} Interest Rate
                  </div>
                  <div id="business-savings-apy">{getProductValue("Business Savings", "APY")} APY</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table-responsive">
          <table id="business-savings-rates-table" className="table mb-4">
            <thead>
              <tr>
                <th colSpan="2" className="h4">
                  <Link
                    id="business-money-market-link"
                    className="d-none d-md-inline-block"
                    to="/business-banking/business-savings-account#business-savings-overview-section"
                  >
                    Business Money Market
                  </Link>
                  <Link
                    id="business-money-market-link"
                    className="d-md-none d-inline-block"
                    to="/business-banking/business-savings-account#m-money-market"
                  >
                    Business Money Market
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "50%" }} id="business-money-market-description">
                  {getProductValue("Business Money Market", "Description")}
                </td>
                <td style={{ width: "50%" }}>
                  <strong>{getProductValue("Business Money Market", "Tier 1 Price Range")}</strong>
                  <br />
                  <span id="business-money-market-tier-1-interest-rate">
                    {getProductValue("Business Money Market", "Tier 1 Interest Rate")} Interest Rate
                  </span>
                  <br />
                  <span id="business-money-market-tier-1-apy">
                    {getProductValue("Business Money Market", "Tier 1 APY")} APY
                  </span>
                  <br />
                  <strong>{getProductValue("Business Money Market", "Tier 2 Price Range")}</strong>
                  <br />
                  <span id="business-money-market-tier-2-interest-rate">
                    {getProductValue("Business Money Market", "Tier 2 Interest Rate")} Interest Rate
                  </span>
                  <br />
                  <span id="business-money-market-tier-2-apy">
                    {getProductValue("Business Money Market", "Tier 2 APY")} APY
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table-responsive">
          <table id="business-savings-rates-table" className="table mb-4">
            <thead>
              <tr>
                <th colSpan="2" className="h4">
                  <Link
                    id="business-interest-checking-link"
                    className="d-none d-md-inline-block"
                    to="/business-banking/business-checking-account#business-checking-overview-section"
                  >
                    Business Interest Checking
                  </Link>
                  <Link
                    id="business-interest-checking-link"
                    className="d-md-none d-inline-block"
                    to="/business-banking/business-checking-account#m-interest-checking"
                  >
                    Business Interest Checking
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "50%" }} id="business-interest-checking-description">
                  {getProductValue("Stellar Business Interest Checking", "Description")}
                </td>
                <td style={{ width: "50%" }}>
                  <strong>{getProductValue("Stellar Business Interest Checking", "Tier 1 Price Range")}</strong>
                  <br />
                  <span id="business-interest-checking-tier-1-interest-rate">
                    {getProductValue("Stellar Business Interest Checking", "Tier 1 Interest Rate")} Interest Rate
                  </span>
                  <br />
                  <span id="business-interest-checking-tier-1-apy">
                    {getProductValue("Stellar Business Interest Checking", "Tier 1 APY")} APY
                  </span>
                  <br />
                  <strong>{getProductValue("Stellar Business Interest Checking", "Tier 2 Price Range")}</strong>
                  <br />
                  <span id="business-interest-checking-tier-2-interest-rate">
                    {getProductValue("Stellar Business Interest Checking", "Tier 2 Interest Rate")} Interest Rate
                  </span>
                  <br />
                  <span id="business-interest-checking-tier-2-apy">
                    {getProductValue("Stellar Business Interest Checking", "Tier 2 APY")} APY
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className="container">
        <h2>Fixed Rate Certificates of Deposit</h2>
        <p>$1,000 minimum to open. A penalty may be assessed for early withdrawal.</p>
        <div className="table-responsive">
          <table id="business-cd-rates-table" className="table">
            <thead>
              <tr>
                <th style={{ width: "25%" }}>Choice of Terms</th>
                <th style={{ width: "25%" }}>
                  {getProductValue("Business Money Market", "Tier 1 Price Range")}
                  <br />
                  Interest Rate/APY %
                </th>
                <th style={{ width: "25%" }}>
                  {getProductValue("Business Money Market", "Tier 2 Price Range")}
                  <br />
                  Interest Rate/APY %
                </th>
                <th style={{ width: "25%" }}>Renewal Term</th>
              </tr>
            </thead>
            <tbody>
              {buildCdRow(_filterCdRecords("14 Days - 2 Months", "14 Days - 2 Months"), buildCdRowOpts)}
              {buildCdRow(_filterCdRecords("3 Months", "3 Months"), buildCdRowOpts)}
              {buildCdRow(_filterCdRecords("4 - 5 Months", "4 - 5 Months"), buildCdRowOpts)}
              {buildCdRow(_filterCdRecords("6 - 11 Months", "6 - 11 Months"), buildCdRowOpts)}
              {buildCdRow(_filterCdRecords("12 - 17 Months", "12 - 17 Months"), buildCdRowOpts)}
              {buildCdRow(_filterCdRecords("18 - 23 Months", "18 - 23 Months"), buildCdRowOpts)}
              {buildCdRow(_filterCdRecords("24 - 35 Months", "24 - 35 Months"), buildCdRowOpts)}
              {buildCdRow(_filterCdRecords("36 - 47 Months", "36 - 47 Months"), buildCdRowOpts)}
              {buildCdRow(_filterCdRecords("48 - 59 Months", "48 - 59 Months"), buildCdRowOpts)}
              {buildCdRow(_filterCdRecords("60 Months", "60 Months"), buildCdRowOpts)}
            </tbody>
          </table>
        </div>
        <p className="mt-5">
          <a
            id="business-rates-pdf"
            target="_blank"
            title="Consumer Business Rates"
            href="/documents/rates-consumer-business-website.pdf"
          >
            Download Business Rates sheet
          </a>
          <br />
          <a
            id="ca-business-rates-pdf"
            target="_blank"
            title="CA Consumer Business Rates"
            href="/documents/wafd-bank-california-consumer-business-rate-sheet.pdf"
          >
            Download California Business Rates sheet
          </a>
        </p>
      </section>
    </SecondaryLanding>
  );
};

export default BusinessRates;
